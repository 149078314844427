import React from 'react';
import userForm from '../../Hooks/useForm'
import { Button, TextField  } from 'rmwc';
 import {GET_USER, TOKEN_POST} from '../../Api'
 import {UserContext} from '../../UserContext'
function Login() {
  const {userLogin, error, loading, socialLogin, guestLogin} = React.useContext(UserContext)
  const username = userForm('email')
  const password = userForm('password')
  async function login(event){
    event.preventDefault();
    try {
      if(username.validate() && password.validate()){
        userLogin(username.value, password.value)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function providerLogin(provider){
    //socialLogin(provider)
    window.location.href=`https://api.tmenu.com.br/v1/login/${provider}`
  }

  async function gLogin(){
    guestLogin()
  }

 
    return (
      <>
      <div className="loginContainer">
        <div className="loginForm">
          <div className='socialButtons'>
            <Button label={"Login com facebook"} disabled={loading} className="btnFace" onClick={()=>providerLogin('facebook')} icon={{icon:'/images/facebook_color.png', size:'small'}}/>
            <Button label={"Login com google"} disabled={loading} className="btnGoogle" onClick={()=>providerLogin('google')} icon={{icon:'/images/google.png', size:'small'}}/>
            <Button label={"Login como convidado"} disabled={loading} className="btnGuest" onClick={gLogin} icon={{icon:'input', size:'small'}}/>
          </div>
          {/* <h3>Ou entre com seu email</h3>
          <form className="form" onSubmit={login}>
          <TextField label="Email" type="text" {...username} required/>
          <p>{username.error}</p>
          <TextField label="Senha" type="password" {...password} required/><br/>
          <p>{password.error}</p>
          {error && <div className='signupError'>{error === 'Unauthorized'?"Usuário ou senha inválido":error}</div>}
          <Button  disabled={loading?true:false} className={"signupBtn"} label={loading?"Aquarde...":"Entrar"} icon="input" />

          <p>Não tem conta? <a href="/signup">Cadastre-se</a></p>
          </form> */}
        </div>
      </div>
      </>
    );
  }
  
  export default Login;
import React from 'react';
import{
  IconButton,
  CircularProgress,
  Button
} from 'rmwc'
import {POST_ORDER, GET_ORDERS, PUT_CLOSE_ACCOUNT}  from '../../Api'
import {UserContext} from '../../UserContext'
import { useNavigate, useLocation } from 'react-router-dom';
import AccountIndex from '../Account/AccountIndex';

function CartIndex({ open, setOpen, 
  stageOne, setStageOne,
  stageTwo, setStageTwo,
  stageAccount, setStageAccount,
  contentConfirmed, setContentConfirmed
}) {
  const {chartCount, updatedChart, updateChart,  getChart, statusChart, setStatusChart } = React.useContext(UserContext)
  const  chartJson = window.localStorage.getItem('tm-chart')
  const slug = window.localStorage.getItem('slug')
  const token = window.localStorage.getItem('tmenu-ct')
  const [chart, setChart] = React.useState(null)
  // const [stageOne, setStageOne] = React.useState(true)
  // const [stageTwo, setStageTwo] = React.useState(false)
  // const [stageAccount, setStageAccount] = React.useState(false)
  const [messageAccount, setMessageAccount] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [type, setType] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [contentQuest, setContentQuest] = React.useState(false)
  //const [contentConfirmed, setContentConfirmed] = React.useState(false)
  ///ACCOUNT VARIABLES///// 
  const [confirmedMessage, setConfirmedMessage] = React.useState('')
  const [card, setCard] = React.useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  
  function getChartAll(){
    setStageOne(true)
    setStageTwo(false)
   setMessageAccount(false)
    let cht = JSON.parse(chartJson)
    setChart(cht)
  }

  function totalChar(chart) {
    let total = 0.0
    chart && chart.map(item =>{
      total +=item.value
    })
    return parseFloat(total).toFixed(2)
  }

  function redirect(path){
    window.location.href=`/${path}`
  }

  function closeChart(){

  }

  async function addOrder(){
    try {
      if(!token){
        window.localStorage.setItem('history', location.pathname)
        navigate("/login")
        setOpen(false)
      }else if(slug){
        setLoading(true)
        const {url, options} = POST_ORDER(token, setBody(chart, slug))
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        window.localStorage.removeItem('tm-chart')
        window.localStorage.removeItem('history')
        setStatusChart(false)
        updateChart()
        getChart()
        getOrder()
        setStageOne(false)
        setStageTwo(true)       
        //setContentQuest(false)
      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }

  function finishChart(){
    window.localStorage.removeItem('open-chart')
    setStageOne(false)
    setStageTwo(false)
    setOpen(false)
    getChartAll()
    // const slug = window.localStorage.getItem('slug')
    // if(slug){
    //   window.location.href=`/mesa/${slug}`  
    // }else{
    //   window.location.href=`/`
    // }

  }

  function setBody(chart, slug){
    const itens = []
    chart.map(prod=>{
      itens.push({
        product_id:prod.product_id,
        quantity:prod.quantity,
        observation:prod.observation?prod.observation:'',
        attributes:prod.attributes?prod.attributes:[]
      })
    })
    const body ={
      hashcode: slug,
      itens:itens
    }
    return body
  }

  function updateQuantityItemChart(item, operand){
    const idx = chart.findIndex((it)=>it.product_id === item.product_id)
    if(operand === '+'){
      chart[idx].quantity += 1
      chart[idx].value += chart[idx].price
    }else{
      if(chart[idx].quantity>1){
        chart[idx].quantity -= 1
        chart[idx].value -= chart[idx].price
      }
    }
    window.localStorage.setItem('tm-chart',JSON.stringify(chart))
    getChartAll()
    updateChart()
  }
  function getAccount(type) {
    if(type === 1){
      setMessageAccount("Tem certeza que deseja pedir apenas a sua conta?")
      setMessage("Tem certeza que deseja pedir apenas a sua conta?")
      setType(1)
    }else if(type === 2){
      setMessageAccount("Tem certeza que deseja pedir a conta da mesa?")
      setMessage("Tem certeza que deseja pedir a conta da mesa?")
      setType(2)
    }
    setStageOne(false)
    setStageTwo(false)
    //setStageAccount(true)
    setContentQuest(true)
    
  }



  //////Account functions////////
  async function getOrder(){
    try {
        if(token){
            const {url, options} = GET_ORDERS(token)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {cards} = await response.json()
            const idx = cards.data.findIndex((card)=>card.status === true)
            const card = cards.data[idx]
            setCard(card)
        }
        return null  
    } catch (error) {
        console.log(error)
        return null
    }
} 

async function nextStep(){
    setContentQuest(false)
    setContentConfirmed(true)
    try {
        if(card){
            const scope = type === 1?'card':'table'
            const idScope = type === 1?card.id:card.table_id
            const {url, options} = PUT_CLOSE_ACCOUNT(token, scope, idScope)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            setConfirmedMessage("A CONTA FOI SOLICITADA COM SUCESSO!")
        }else{
            setConfirmedMessage("Não identificamos sua conta!")
        }
    } catch (error) {
        setConfirmedMessage("Ocorreu um erro inesperado, tente movamente em instantes!")
    }     
}
function closeAll(){
    setContentQuest(true)
    setContentConfirmed(false)
    setOpen(false)
}

function closeConfirmed() {
    setContentQuest(false)
    setContentConfirmed(false)
    setOpen(false)
    //window.location.href="/meus-pedidos"
}

/////////AND ACCOUNT FUNCITONS////////////////
  React.useEffect(()=>{
    getChartAll()
  },[chartCount, updatedChart])

  React.useEffect(()=>{
    getOrder()
  },[])
    return (
      <>
      {loading && <div className="loading" ><CircularProgress size={80} /></div>}
      { stageOne && !loading && !stageTwo &&<div className="charContainer">
        <h1>Pedidos</h1>
        <div >
          {
            chart && chart.map(item=>{
              return(
                <div className="qtyChart" key={item.name}>
                  <button  className="plusMinusChart" onClick={()=>{updateQuantityItemChart(item, '-')}}>-</button>
                    <div className="qtyInputChart"> {item.quantity} </div>
                  <button className="plusMinusChart" onClick={()=>{updateQuantityItemChart(item, '+')}}>+</button>
                  <div className="qtyInputChart"> {item.name} (R$ {parseFloat(item.value).toFixed(2)})</div>
                </div>
              )
            })
          }
        </div>
        <h3 >
          Total R${totalChar(chart)} 
        </h3>
        <div className="buttonChart">
          { chart && chart.length > 0 && 
          <>
            {/*<button onClick={addOrder} className="btnConfirme">Confirmar</button>*/}
            <Button label="Confirmar" onClick={addOrder} icon={{icon:'check', size:'small'}} className={"btnConfirme"}/>
            <p>ou</p>
          </>
          }
          {/*<button onClick={finishChart} className="btnAdd">Adicionar mais itens</button>*/}
          <Button label="Adicionar mais" onClick={finishChart} className="btnAdd" icon={{icon:'add', size:'small'}}/>
        </div>
      </div>}
      {
        stageTwo  && !loading && <div className="charContainer">
          <div className="divImage">
          <img className="centerImg" src="/images/confirme.png" alt=""/>
          </div>
         <h2> PEDIDO ENVIADO COM SUCESSO!</h2>
         <p>Em breve Chegara em sua mesa!</p>
         <div className="buttonChart">
         {/*<button onClick={finishChart} className="btnAdd">Voltar para cardapio</button>*/}
         <Button label="Voltar" onClick={finishChart} className={"btnAdd"} icon={{icon:"add", size:'small'}}/>
         <p>ou</p> 
         <Button onClick={()=>{getAccount(1)}} className={"tmenuGreenBtn"} label="Pedir minha conta" icon={{icon:'/images/person_white.svg', size:'small'}}/>
         <Button onClick={()=>{getAccount(2)}} className={"tmenuRedBtn"} label="Pedir a conta da mesa" icon={{icon:'/images/restaurant_white.svg', size:'small'}}/>
         </div>
        </div>

      } 
 
           { contentQuest && !stageTwo && !stageOne && !loading &&
        <div className="charContainer">
            <div className="divImage">
            <img className="exclamacaoImg" src="/images/exclamacao.png" alt=""/>
            </div>
            <p>{message}</p>
            <div className="buttonChart">
            <button onClick={closeAll} className="btnNotAccount">Não</button>
            <button onClick={nextStep} className="btnConfirmAccount">Sim</button>
            </div>
        </div>
        }

        {
            contentConfirmed && <div>
                {confirmedMessage && 
                    <div className="charContainer">
                        <div className="divImage">
                            <img className="centerImg" src="/images/confirme.png" alt=""/>
                        </div>
                    <h2>{confirmedMessage}</h2>
                    <p>Aguarde que o garçom levará na sua mesa!</p>
                    </div>}
                <div className="buttonChart">
                <button onClick={closeConfirmed} className="btnConfirme">Voltar</button>
                </div>
            </div>
        }
          
      </>
    );
  }
  
  export default CartIndex;

  export function addChart(item, quantity, attributes){
    const body = {
      product_id:item.product_id,
      name:item.name,
      quantity: quantity,
      observation:item.observation,
      value:item.value*quantity,
      price:item.value,
      attributes:attributes
    }
    console.log("ADD CHART", body)
    let chart = window.localStorage.getItem('tm-chart')
    if(chartIsNull(chart)){
      window.localStorage.setItem('tm-chart',JSON.stringify([body]))  
    }else{
      let cht = JSON.parse(chart)
      const idx = getItemChart(cht, item.product_id)
      if(idx>=0){
        cht[idx].quantity += quantity
        cht[idx].value += item.value
      }else{
        cht.push(body)
      } 
     window.localStorage.setItem('tm-chart',JSON.stringify(cht))
    }    
  }

  export function chartIsNull(chart){
    return chart === null
  }

  export function getItemChart(chart, id){
    const idx = chart.findIndex((item)=>item.product_id === id)
    return idx
  }
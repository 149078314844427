import React from 'react';
import { useHistory, useParams } from 'react-router-dom'
import "./Home.css";
import ListaCardapioHome from './ListaCardapioHome';

function Home() {
  const {slug} =  useParams()

  const oldSlug = window.localStorage.getItem('slug')
  const token = window.localStorage.getItem('tmenu-ct')

  // function openApp(){
  //   if(token){
  //     if(slug){
  //       window.localStorage.setItem('slug', slug)
  //     }else if(oldSlug){
  //       window.localStorage.setItem('slug', oldSlug)
  //     }else{
  //       window.location.href=`/meus-pedidos`
  //     }     
  //   }else if(slug && oldSlug !==slug){
  //     window.localStorage.setItem('slug', slug)
  //   }else if(!slug && !oldSlug){
  //       window.location.href="/mesa/0"
  //   }
  // }

  
  function openApp(){ 
    if(slug){
      window.localStorage.setItem('slug', slug)
      //window.location.href=`/mesa/${slug}`
    }else if(token){
      window.localStorage.setItem('slug',oldSlug)
    }
  }
  React.useEffect(()=>{
    openApp()
  },[])
  return (
    <>
      <ListaCardapioHome slug={slug?slug:oldSlug}/>
    </>
  );
  }  
  export default Home;
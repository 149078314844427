import React from "react";

import './Global.css';
import "rmwc/dist/styles";
import "material-icons";

import Home from './Components/Home';
import Authenticator from './Components/Auth/Authenticator'
import Header from './Components/Header';
import MainMenu from './Components/MainMenu';
import Main from './Components/Main/Main'
import { Link, BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./Helper/ProtectedRoute";
import { UserStorage } from './UserContext';
import CartIndex from "./Components/Cart/CartIndex";
import TableOrdersIndex from "./Components/Orders/TableOrdersIndex";
import ProfileIndex from "./Components/Profile/ProfileIndex"
import Login from "./Components/Auth/Login";
import SignUp from "./Components/SignUp";
function App() {
  return (
    <>
      <div className={"MasterContainer"}>
     
       { /*<Header/>*/}
        <BrowserRouter>   
        <UserStorage> 
        <Header/> 
          <Routes>
          <Route path="/user/:token" element={< Authenticator />}/>
            <Route path="/" element={<Home />}/>
            <Route path="/login" element={<Login />}/>
            <Route path="/signup" element={<SignUp />}/>
            <Route  path="/mesa/:slug" element={<Home />} />
            <ProtectedRoute path="/meus-pedidos/" element={<TableOrdersIndex />} />
            <ProtectedRoute path="/profile/" element={<ProfileIndex />} />            
          </Routes>  
          <MainMenu/>  
          </UserStorage>     
        </BrowserRouter>      
      
      </div>   
    </>
  );
}

export default App;
import React from 'react';
import {GET_USER, PUT_USER} from '../../Api'
import {CircularProgress, 
  Typography,
  Snackbar,
  SnackbarAction,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  DialogButton,
  Button
} from 'rmwc';
import ButtonCall from '../ButtonCallWaiter/ButtonCall'
import {UserContext} from '../../UserContext'
import MainMenu from '../MainMenu';
function ProfileIndex() {
  const {user, userLogout, setUpdateUser, loged} = React.useContext(UserContext)
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [cpf, setCpf] = React.useState('')
  const [openSnack, setOpenSnack] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [messageSnack, setMessageSnack] = React.useState('')
  const token = window.localStorage.getItem('tmenu-ct')
 

  async function getData(){
    try {
      if(!token){
        window.location.href="/login"
      }
      const {url, options} = GET_USER(token)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {user} = await response.json()
      setName(user.name)
      setPhone(user.phone)
      setEmail(user.email)
      setCpf(user.cpf?user.cpf:'Não cadastrado')
    } catch (error) {
      console.log(error)
    }
  }
  async function updateUser(){
    try {
      setLoading(true)
      const body ={
        name,
        email,
        phone,
        cpf
      }
      const {url, options} = PUT_USER(token, body, user.id)  
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)     
      setUpdateUser(token)
      setMessageSnack('Dados atualizados')
      setOpenSnack(true)
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }
  React.useEffect(()=>{
    getData()
  },[])

    return (
      <>

      <Dialog
        open={open}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpen(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogContent className={"editProfileContainer"}>
        <TextField name="name"  label="Nome"  value={name} onChange={({target})=>setName(target.value)} className="inputData"/>
        <TextField name="email" label="Email"  value={email} onChange={({target})=>setEmail(target.value)} className="inputData"/>
        <TextField name="phone" label="Telefone"  value={phone} onChange={({target})=>setPhone(target.value)} className="inputData"/>
        <TextField name="cpf" label="cpf"  value={cpf} onChange={({target})=>setCpf(target.value)} className="inputData"/>
        </DialogContent>
         <DialogActions>
          <DialogButton action="close">Cancel</DialogButton>
          <DialogButton action="accept" isDefaultAction onClick={updateUser}>
            Salvar
          </DialogButton>
        </DialogActions>
      </Dialog>

         { !user && <div className="loadingProfile" ><CircularProgress size={80} /></div>}
          {user && 
            <div className="profileContainer">
              <Typography use="headline6" className={"inputName"}><span>{user.name}</span></Typography>
              <p><Typography use="headline6">Email: </Typography><Typography use="overline">{user.email}</Typography></p>
              <p> <Typography use="headline6">Telefone: </Typography><Typography use="overline">{user.phone}</Typography></p>
              <p><Typography use="headline6">CPF: </Typography><Typography use="overline">{user.cpf?user.cpf:"Não informado"}</Typography></p>
              <div className="btnGroupProfile">
              {/*<buttom onClick={()=>setOpen(true)} className="btnProfileSave">EDITAR</buttom>*/}
              <Button label="Editar" className={"btnProfileSave"}onClick={()=>setOpen(true)} icon={{icon:'edit', size:'small'}}/>
              <Button label="Sair" className={"btnProfileLogout"}onClick={()=>userLogout()} icon={{icon:'logout', size:'small'}}/>
              {/*<buttom onClick={()=>userLogout()} className="btnProfileLogout">SAIR</buttom>*/}
              </div>
            </div>
          }
          <Snackbar
        open={openSnack}
        onClose={evt => setOpenSnack(false)}
        message={messageSnack}
        dismissesOnAction
        action={
          <SnackbarAction
            label="Ok"
            onClick={() => setMessageSnack('')}
          />
        }
      />
      <ButtonCall />
      </>
    );
  }
  
  export default ProfileIndex;
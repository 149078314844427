import React from 'react';
import BannerHeader from '../Tmenu_Header_App.png';
import Logo from '../logo-tmenu-white-top.png';
import { Button, Typography, Icon, 
  SimpleDialog, TextField, Dialog,
  DialogContent, IconButton,
  Checkbox, Snackbar,
  SnackbarAction
} from 'rmwc';
import { useParams } from 'react-router-dom'
import { UserContext } from '../UserContext';
import {API_URL, SEARCH, GET_PRODUCT} from '../Api'
import useForm from '../Hooks/useForm'
import { addChart, getItemChart } from '../ChartActions';

function Header() {
  const slug = window.localStorage.getItem('slug')
  const { company, getData,getChart, updateChart } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [products, setProducts] = React.useState(null)
  const [product, setProduct] = React.useState(null)
  const [attributes, setAttributes] = React.useState(null)
  const [orderQuantity, setOrderQuantity] = React.useState(1)
  const [chartAttributes, setChartAttributes] = React.useState([])
  const [messageSnack, setMessageSnack] = React.useState('')
  const [openSnack, setOpenSnack] = React.useState(false);
  const observation = useForm('')
  const search = useForm('')
  // const [activeTab, setActiveTab] = React.useState(0);


  async function searchProduct(){
    try {
      setOpen(false)
      const {url, options} = SEARCH(search.value, 1, 100, company.id)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {products} = await response.json()
      setProducts(products.data)
      setOpenDialog(true)
      search.setValue('')
    } catch (error) {
      console.log(error)
    }
  }

  ////////REFATORAR/////////
  async function handleClick(product_id){
    try {
        const{url, options} = GET_PRODUCT(product_id)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        const {product} = await response.json()           
        addCount(product.attributes)
        setProduct(product)
        setAttributes(product.attributes)
        setOpenDialog(false)
        setOpenModal(true);
    } catch (error) {
        console.log(error)
    }
}

    function addItemToChart(item, quantity){
        addChart(item, quantity, chartAttributes)
        setOpenModal(false)
        getChart()
        updateChart()
        setMessageSnack("Ok, Agora confirme o pedido")
        setOpenSnack(true)
        observation.setValue('')
    }
    function addComplement(value,checked){
        console.log("CHART-ATTRIBUTE", chartAttributes)
        const idx = attributes.findIndex((att)=>att.id === value.attribute_id)
        const idxv = attributes[idx].values.findIndex((val)=>val.id === value.id)
        const idxa = chartAttributes.findIndex((att)=>att.attribute_id === value.attribute_id)
        if(checked){
            if(attributes[idx].count < attributes[idx].max_item){
                attributes[idx].count += 1
                attributes[idx].values[idxv].checked = true                    
                console.log("INDEX", idxa)
                if(idxa >= 0){
                    console.log("MAIOR IGUAL ZERO")
                    chartAttributes[idxa].values.push({name_value:value.name, additional_value:value.additional_value, quantity:value.max_item})
                    setChartAttributes([...chartAttributes])
                }else{
                    console.log("MENOR QUE ZERO")
                    setChartAttributes([...chartAttributes,{attribute_id:value.attribute_id, quantity:attributes[idx].max_item,values:[{
                        name_value:value.name, additional_value:value.additional_value, quantity:value.max_item
                    }]}])
                }
                
                //setChartAttributes([...chartAttributes,{attribute_id:attributes[idx].id, quantity:attributes[idx].max_item,values:[]}])
            }            
            setAttributes([...attributes]) 
            console.log(attributes[idx].count)
        }else{
            if(attributes[idx].count>0){
                attributes[idx].count -= 1
                attributes[idx].values[idxv].checked = false
                const idxn = chartAttributes[idxa].values.findIndex((val)=>val.name_value === value.name_value)
                if(chartAttributes[idxa].values.length>1){
                    chartAttributes[idxa].values.splice(idxn, 1)
                }else{
                    chartAttributes.splice(idxa,1)
                }
            }
            setAttributes([...attributes])  
            console.log(attributes[idx].count)
        }
        
    }
    function addCount(attributes){
        attributes.map(att=>{
            att.count = 0
            att.values.map(value=>{
                value.checked = false
            })
        })
    }



  ///////////////////////
React.useEffect(()=>{
  getData(slug)
},[])

  return (
    <>

      <Dialog
          open={openModal}
          onClose={evt => {
          console.log(evt.detail.action);
          setOrderQuantity(1)
          setOpenModal(false);
          }}
          onClosed={evt => setAttributes(null)}
      >        
                  <div className="productImageModal">
                  <img src={product && product.images.length>0?`${API_URL}download/img/${product.images[0].path}`:"images/image_not_found.png"} alt=""/>
                  </div>
                  <div className="produtctDetailsModal">
                      <h2 className="productTitle">{product?product.name:""}</h2>
                      <p className="productDescription">{product?product.description:""}</p> 
                      {   attributes &&
                          attributes.length>0 && attributes.map(
                              att=>{
                                  return(
                                      <div key={att.id} className="attributeContainer">
                                          <div className="nameCountAtt">
                                          <div className="nameAttribute">{att.title} </div><div className="countAttribute">{att.count}/{att.max_item}</div><div className="requiredAttribute">{att.required?"OBRIGATÓRIO":"OPCIONAL"}</div>
                                          </div>
                                          <div className="descriptionAttribute">{att.description}</div>
                                          {att.values.map(value=>{
                                              return(
                                                  <div key={value.id} className="attValue">
                                                      <div className="nameValue">{value.name}</div> 
                                                      <div className="addValue">
                                                      <Checkbox label="" onChange={evt=>addComplement(value, evt.currentTarget.checked) } disabled={att.count === att.max_item && value.checked === false ?true:false}/>
                                                      </div>
                                                  </div>
                                              )
                                          })}
                                      </div>
                                  )
                              }
                          )
                      }       
                      <TextField label="Deixe uma observação" type="text" {...observation} />                     
                      <p className="productPrice">R$ {product?(product.value*orderQuantity).toFixed(2):0}</p>
                      <div className="modalItemControl">
                          <div className="qtyControl">
                              <IconButton icon="remove" className="plusMinus" onClick={()=>{setOrderQuantity(orderQuantity>1?orderQuantity-1:orderQuantity-0)}}/>
                                  <div className="qtyInput">{orderQuantity}</div>
                              <IconButton icon="add" className="plusMinus"  onClick={()=>{setOrderQuantity(orderQuantity+1)}}/>
                          </div>
                          {product && <Button className="produtctDetailsBtnAdd" label="Adicionar" onClick={()=>{addItemToChart({product_id:product.id, name:product.name, observation:observation.value, value:product.value}, orderQuantity)}}/>}
                      </div>
                  </div>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenDialog(false);
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        <DialogContent>
          <div className="containerProductCatList" >
            <ul>
                {
                    products && products.map(product=>{
                        return(
                          <li onClick={()=>handleClick(product.id)} key={product.id}>                         
                              <div className="produtctDetails">
                                  <h2 className="productTitle">{product.name}</h2>
                                  <p className="productDescription">{product.description}</p>
                                  <p className="productPrice">R$ {product.value.toFixed(2)}</p>                                
                              </div>
                              <div className="productImage">
                                  <img src={product.images.length>0?`${API_URL}download/img/${product.images[0].path}`:"images/image_not_found.png"} alt=""/>
                              </div>                                            
                          </li>
                        )
                    })
                }                                     
              </ul>                
          </div>
        </DialogContent>
      </Dialog>
    <header className="TmenuHeader">
    <div className={"BarBottomHeader"}>      
        <Typography use="body2"><img src={Logo} alt="Logo Tmenu"/></Typography>
          <SimpleDialog className={"ModalBuscaHeader"}
          title="Buscar item"
          open={open}
          onClose={evt => {            
            setOpen(false);  
            search.setValue('')        
          }}>
            <TextField fullwidth placeholder="Ex: Pizza, Hambúrguer, Heineken..." {...search}/>            
            <Button label="Buscar" unelevated onClick={searchProduct}/>
          </SimpleDialog>
          <Icon icon="search" onClick={() => setOpen(true)} /> 
      </div>
      {company && <img src={company.image?`${API_URL}download/img/${company.image}`:BannerHeader} alt="Banner do Cabeçalho"/>}
      {!company && <img src={BannerHeader} alt="Banner do Cabeçalho"/>}
      <div className={"BarBottomHeader"} style={{padding: "1% 8%", justifyContent: "center", background: "#202221"}}>      
      <Typography use="body2">{company?company.name+" - ":''} {company?"Mesa":"Escolha uma mesa"} <span><strong>{company?company.table:" "}</strong></span></Typography> 
      </div>
      
    </header>
    {/* <TabBar className={"TabCats"} activeTabIndex={activeTab}
      onActivate={evt => setActiveTab(evt.detail.index)}>
      <Tab>Hambúrguers</Tab>
      <Tab>Refrigerantes</Tab>
      <Tab>Cervejas</Tab>
    </TabBar> */}  


<Snackbar
        open={openSnack}
        onClose={evt => setOpenSnack(false)}
        message={messageSnack}
        dismissesOnAction
        action={
          <SnackbarAction
            label="Ok"
            onClick={() => setMessageSnack('')}
          />
        }
      />
    </>
  );
}

export default Header;

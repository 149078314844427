import React from 'react';
import { List, CollapsibleList, SimpleListItem, Button, IconButton,
    CircularProgress, Grid, GridRow, GridCell,Icon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogButton,
    Badge,
    Snackbar,
    SnackbarAction
  } from 'rmwc';
import {GET_ORDER, GET_ORDERS, PUT_CLOSE_ACCOUNT, POST_ORDER} from '../../Api'
import {convertDate} from '../../Utils'
import { addChart } from '../../ChartActions'
import {UserContext} from '../../UserContext'
import { config } from '../../ApiUrls';
import Ws from '@adonisjs/websocket-client'
import ButtonCall from '../ButtonCallWaiter/ButtonCall';
function TableOrdersIndex() {
  const urlWs  = config.url.URL_WS
  const { chartCount, getChart, updateChart } = React.useContext(UserContext)
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const token = window.localStorage.getItem('tmenu-ct')
  const [userLog, setUserLog] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState(null) 
  const [order, setOrder] = React.useState(null)
  const [orderId, setOrderId] = React.useState(null)
  const [orderIdSxtract, setOrderIdStract] = React.useState(null)
  const [cards, setCards] = React.useState(null)
  const [contentExtract, setContentExtract] = React.useState(false)
  const [contentQuest, setContentQuest] = React.useState(false)
  const [contentConfirmed, setContentConfirmed] = React.useState(false)
  const [messageQuest, setMessageQuest] = React.useState('')
  const [messageSnack, setMessageSnack] = React.useState('')
  const [cardOrTable, setCardOrTable] = React.useState(null)
  const [responseRequestAccount, setResponseRequestAccount] = React.useState(null)
  const ws = Ws(urlWs).withApiToken(token).connect()
  const uptade = ws.subscribe('update-order')
  let [total, setTotal]  = React.useState(0)

  async function getData(){
    try {
      if(!token){
        window.location.href="/login"
      }
      
      const {url, options} = GET_ORDERS(token)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {cards} = await response.json()
      if(cards){
        setData(cards.data)
      setUserLog(cards.data[0].user_id)
      }    
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }

  async function getOrder(id){
   try {
     setOrderId(id)
     setOrderIdStract(id)
      const idx = data.findIndex((order)=>order.id === id)
      const {url, options} = GET_ORDER(token, data[idx].unified_card)
      const response = await fetch(url, options)
      if(!response.ok) throw new Error(response.statusText)
      const {cards} =await response.json()
      setOrder(data[idx])
      setCards(cards)
      setTotal(cards.reduce(getSum, 0))
      setOpen(true)
      setContentExtract(true)
   } catch (error) {
     console.log(error)
   }
  }

  function getSum(total, num){
    return num.status?total+num.value:total+0
  }

  function openQuestCard(){
    setMessageQuest('Tem certeza que deseja pedir apenas a sua conta?')
    setContentQuest(true)
    setContentExtract(false)
    setCardOrTable(0)
  }

  function openQuestTable(){
    setMessageQuest('Tem certeza que deseja pedir a conta da mesa?')
    setContentQuest(true)
    setContentExtract(false)
    setCardOrTable(1)
  }

  function closeAll(){
    setOpen(false)
    setContentQuest(false)
    setContentExtract(false)
    setCardOrTable(null)
    setContentConfirmed(false)
  }

  async function confirmRequest(){
    setContentQuest(false)   
    try {
      let idscope = 0
      let scope = ''
      if(cardOrTable === 0){
        scope = 'card'
        idscope = order.id
      }else if(cardOrTable === 1){
        scope = 'table'
        idscope = data[0].table_id
      }
      const {url, options} = PUT_CLOSE_ACCOUNT(token, scope, idscope)
      const response = await fetch(url, options)    
      if(response.ok){
        const {message} = await response.json()
        setResponseRequestAccount(message)
      }else{
        const {error} = await response.json()
        setResponseRequestAccount(error)
      }
      setContentConfirmed(true)
      } catch (error) {
        console.log(error)
      }
  }
  
  function addItemToChart(item, quantity){
    addChart(item, quantity)
    setMessageSnack('Pedido enviado!')
    setOpenSnack(true)
    getChart()
    updateChart()
  }

  async function addOrder(item){
    try {
      const slug = window.localStorage.getItem('slug')
      if(slug){
        setLoading(true)
        const {url, options} = POST_ORDER(token, setBody(item, slug))
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        //window.localStorage.removeItem('tm-chart')
        setMessageSnack('Pedido enviado!')
        setOpenSnack(true)
        getOrder(orderId)
      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }

  function setBody(item, slug){
    const itens = [
      {
        product_id:item.product_id,
        quantity:1,
        observation:'',
        attributes:[]
      }
    ]
    return {
      hashcode: slug,
      itens:itens
    }
  }

  uptade.on('table-closed', (response)=>{
    getData()
    getOrder(orderIdSxtract)
  })
  React.useEffect(()=>{
    getData()
  },[])
    return (
      <>
      <Dialog
        open={open}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpen(false);
          setOrderId(null)
          setContentQuest(false)
          setContentConfirmed(false)
        }}
        onClosed={evt => console.log(evt.detail.action)}
      >
        { contentExtract && !contentQuest && !contentConfirmed && <DialogContent>
          
           <h3 className="titleExtract"> MESA {order?order.table.number:""} - COMANDA {order?order.id:``}</h3>
          { 
            cards && cards.length>0 &&
            cards.map(card=>{
              
              return(
                <div key={card.id}>
                  <div className="clientName">{card.client}</div>
                  <div className={card.status?"textSub":"textThrough"}>(Sub-total: R$ {card.value.toFixed(2)})</div>
                  {card.itens_values.map(item=>{
                    console.log("CLS STATUS ",item.status)
                    return(<div>
                      {item.status !== 'Cancelado' &&<li key={item.name} className="itemExtract">
                       <div className={card.status?"textRigth":"textThrough"}> {item.quantity} X {item.name} {userLog === card.user_id?<button className="btnAddEqual" disabled={!order.status} onClick={()=>addOrder(item)}>PEDIR MAIS UM</button>:<button disabled={!order.status} onClick={()=>addOrder(item)} className='btnAddOther'>PEDIR UM IGUAL</button>}</div>
                       <div className={card.status?"textRigth":"textThrough"}>(R$ {item.total.toFixed(2)})</div>
                      </li>}</div>
                    )
                  })}
                </div>
              )
            })             
          }
          
           <div className="totalTable">Total da mesa <strong>R$ {total.toFixed(2)}</strong></div>
           <div className="buttonChart">
           <div>
             {/*<button onClick={openQuestCard} disabled={!order.status} className="btnConfirme">$ Pedir apenas minha conta</button>*/}
             <Button className={"tmenuGreenBtn"} onClick={openQuestCard} label="pedir apenas minha conta" icon={{ icon: '/images/person_white.svg', size: 'small' }} disabled={!order.status}/>
             </div>
           <div>
             {/*<button onClick={openQuestTable} disabled={!order.status} className="btnAccountTable">$$ Pedir a conta da mesa</button>*/}
             <Button className={"tmenuRedBtn"}  onClick={openQuestTable} label="Pedir conta da mesa" icon={{ icon: '/images/restaurant_white.svg', size: 'small' }} disabled={!order.status}/>
           </div>
          </div>
        </DialogContent>}
        { contentQuest && 
          <DialogContent>
            <div className="divImage">
              <img className="exclamacaoImg" src="/images/exclamacao.png" alt=""/>
            </div>
          <h3 className="questionAccount">{messageQuest}</h3>
          <div className="buttonChart">
          <button onClick={closeAll} className="btnNotAccount">Não</button>
          <button onClick={confirmRequest} className="btnConfirmAccount">sim</button>
          </div>
          </DialogContent>}
          { contentConfirmed && 
          <DialogContent>

            { 
              responseRequestAccount && 
              <div>
                <div className="divImage">
                            <img className="centerImg" src="/images/confirme.png" alt=""/>
                        </div>
                <h3 className="titleExtract">{responseRequestAccount}</h3>
                <p>Aguarde que o garçom levará na sua mesa!</p>
                </div> 
            }
            <div className="buttonChart">
            <button onClick={closeAll} className="btnConfirme">Meus Pedidos</button>
            </div>

          </DialogContent>}
      </Dialog>
        { loading && <div className="loading" ><CircularProgress size={80} /></div>}
       { !loading && <div className="containerOrders">
        <h1>Meus Pedidos</h1>
        <div className="listOrder">
        {data && data.map(order =>{
          return(
            <div className="listItemOrder" key={order.id}>
              <div className="listRow">
                <div className="tableInfo">Mesa {order.table.number}</div>
                <div className="dataInfo">{convertDate(order.created_at, 'às')}</div>
              </div>
              <div className="listRowBottom">
              <Icon icon="search" className="extratoInfo"/>
                <div className="extratoInfo">               
                  <span onClick={()=>getOrder(order.id)}>Ver extrato</span>
                </div>
                <div className={order.status?"statusInfoTrue":"statusInfoFalse"}>{order.status?"Em Andamento":"Finalizado"}</div>
              </div>
           </div>
          )
        })
        }
          </div>
        </div>}

        <Snackbar
        open={openSnack}
        onClose={evt => setOpenSnack(false)}
        message={messageSnack}
        dismissesOnAction
        action={
          <SnackbarAction
            label="Ok"
            onClick={() => setMessageSnack('')}
          />
        }
      />
      <ButtonCall/>
      </>
    );
  }
  
  export default TableOrdersIndex;
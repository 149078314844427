import React from 'react';
import useForm from '../Hooks/useForm';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextField
} from 'rmwc'
import { POST_USER } from '../Api';

function SignUp() {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [user, setUser] = React.useState(null)

  const navigate = useNavigate()

  const name = useForm('')
  const email = useForm('email')
  const phone = useForm('')
  const cpf = useForm('')
  const password = useForm('')
  const passwordConfirm = useForm('')


  async function signup(event) {
    setError(null)
    event.preventDefault();
    setLoading(true)
    const body = {
      name:name.value,
      email: email.value, 
      password:password.value
    }
    try {
      if(password.value === passwordConfirm.value){
        const {url, options} = POST_USER(body)
        const response = await fetch(url, options)
        console.log(response)
        if(!response.ok){
          const {error} = await response.json()
          setError(error)
          throw new Error(error)
        } 
        const {user} = await response.json()
        if(user) {
          window.location.href="/login"
        }
      }else{
        setError("As senhas são diferentes")
      }
     
    } catch (error) {
      console.log(error)    
    }finally{
      setLoading(false)
    }
    
  }
    return (
      <>    
      <div className="signupContainer">  
          <div className="signupForm">
            <h1>Cadastre-se</h1>
            <form className="form" onSubmit={signup}>
            <TextField label="Nome" type="text" {...name} required/>
            <p>{name.error}</p>
            <TextField label="Email" type="text" {...email} required/>
            <p>{email.error}</p>        
            <TextField label="Senha" type="password" {...password} required/><br/>
            <TextField label="Confirme a Senha" type="password" {...passwordConfirm} required/><br/>
            <p>{password.error}</p>
            {error && <div className='signupError'>{error}</div>}
            <Button  disabled={loading?true:false} className={"signupBtn"} label={loading?"Aquarde...":"Cadastrar"} icon="account_circle"/>
            
            <p>Já tem conta?  <a href="/login">Faça login</a></p>
            </form>
        </div>
      </div>
      </>
    );
  }
  
  export default SignUp;
import React from "react"
import { GET_TABLE, GET_TABLE_SLUG, PUT_CLOSE_ACCOUNT, GET_ORDERS } from "../../Api"

function AccountIndex({message, type, open, setOpen, contentQuest, setContentQuest, contentConfirmed, setContentConfirmed, card}) {
   
    const [confirmedMessage, setConfirmedMessage] = React.useState('')
    const token = window.localStorage.getItem('tmenu-ct')
    const slug = window.localStorage.getItem('slug')
 

    async function nextStep(){
        setContentQuest(false)
        setContentConfirmed(true)
        try {
            if(card){
                const scope = type === 1?'card':'table'
                const idScope = type === 1?card.id:card.table_id
                const {url, options} = PUT_CLOSE_ACCOUNT(token, scope, idScope)
                const response = await fetch(url, options)
                if(!response.ok) throw new Error(response.statusText)
                setConfirmedMessage("A CONTA FOI SOLICITADA COM SUCESSO!")
            }else{
                setConfirmedMessage("Não identificamos sua conta!")
            }
        } catch (error) {
            setConfirmedMessage("Ocorreu um erro inesperado, tente movamente em instantes!")
        }     
    }
    function closeAll(){
        setContentQuest(true)
        setContentConfirmed(false)
        setOpen(false)
    }

    function closeConfirmed() {
        setContentQuest(true)
        setContentConfirmed(false)
        setOpen(false)
    }

    React.useEffect(()=>{
    },[])
    return(
        <>
        <div>
        { contentQuest && 
        <div className="charContainer">
            <div className="divImage">
            <img className="exclamacaoImg" src="/images/exclamacao.png" alt=""/>
            </div>
            <p>{message}</p>
            <div className="buttonChart">
            <button onClick={closeAll} className="btnNotAccount">Não</button>
            <button onClick={nextStep} className="btnConfirmAccount">Sim</button>
            </div>
        </div>
        }

        {
            contentConfirmed && <div>
                {confirmedMessage && 
                    <div className="charContainer">
                        <div className="divImage">
                            <img className="centerImg" src="/images/confirme.png" alt=""/>
                        </div>
                    <h2>{confirmedMessage}</h2>
                    <p>Aguarde que o garçom levará na sua mesa!</p>
                    </div>}
                <div className="buttonChart">
                <button onClick={closeConfirmed} className="btnConfirme">Voltar</button>
                </div>
            </div>
        }
        </div>
        </>
    )
}

export default AccountIndex
import React from 'react';

import { List, CollapsibleList, 
    SimpleListItem, Dialog, 
    Button, IconButton,  
    CircularProgress,
    Checkbox,
    TextField,
    Snackbar,
    SnackbarAction
 } from 'rmwc';
import { API_URL, MENU, GET_PRODUCT } from '../Api';
import { UserContext } from '../UserContext';
import useForm from '../Hooks/useForm';
import { addChart, getItemChart } from '../ChartActions';
import Header from './Header';

import QRCode from '../qr-code.gif'
import ButtonCall from './ButtonCallWaiter/ButtonCall';
const ListaCardapioHome = ({slug}) => {
    const { data, getData, loading, getSlug,getChart, updateChart } = React.useContext(UserContext);
    //const slug = window.localStorage.getItem('slug')
    const [open, setOpen] = React.useState(false);
    const [orderQuantity, setOrderQuantity] = React.useState(1)
    const [product, setProduct] = React.useState(null)
    const [attributes, setAttributes] = React.useState(null)
    const [chartAttributes, setChartAttributes] = React.useState([])
    const token = window.localStorage.getItem('tmenu-ct')
    const [messageSnack, setMessageSnack] = React.useState('')
    const [openSnack, setOpenSnack] = React.useState(false);
    const observation = useForm('')
    async function handleClick(product_id, category_id){
        try {
            const{url, options} = GET_PRODUCT(product_id)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {product} = await response.json()           
            addCount(product.attributes)
            setProduct(product)
            setAttributes(product.attributes)
            setOpen(true);
        } catch (error) {
            console.log(error)
        }
    }
        function addItemToChart(item, quantity){
            addChart(item, quantity, chartAttributes)
            setOpen(false)
            getChart()
            updateChart()
            setMessageSnack("Ok, Agora confirme o pedido")
            setOpenSnack(true)
            observation.setValue('')
        }
        function addComplement(value,checked){
            const idx = attributes.findIndex((att)=>att.id === value.attribute_id)
            const idxv = attributes[idx].values.findIndex((val)=>val.id === value.id)
            const idxa = chartAttributes.findIndex((att)=>att.attribute_id === value.attribute_id)
            if(checked){
                if(attributes[idx].count < attributes[idx].max_item){
                    attributes[idx].count += 1
                    attributes[idx].values[idxv].checked = true                    
                    if(idxa >= 0){
                        chartAttributes[idxa].values.push({name_value:value.name, additional_value:value.additional_value, quantity:value.max_item})
                        setChartAttributes([...chartAttributes])
                    }else{
                        setChartAttributes([...chartAttributes,{attribute_id:value.attribute_id, quantity:attributes[idx].max_item,values:[{
                            name_value:value.name, additional_value:value.additional_value, quantity:value.max_item
                        }]}])
                    }
                }            
                setAttributes([...attributes]) 
                console.log(attributes[idx].count)
            }else{
                if(attributes[idx].count>0){
                    attributes[idx].count -= 1
                    attributes[idx].values[idxv].checked = false
                    const idxn = chartAttributes[idxa].values.findIndex((val)=>val.name_value === value.name_value)
                    if(chartAttributes[idxa].values.length>1){
                        chartAttributes[idxa].values.splice(idxn, 1)
                    }else{
                        chartAttributes.splice(idxa,1)
                    }
                }
                setAttributes([...attributes])  
                console.log(attributes[idx].count)
            }
            
        }
        function addCount(attributes){
            attributes.map(att=>{
                att.count = 0
                att.values.map(value=>{
                    value.checked = false
                })
            })
        }
    React.useEffect(()=>{
        getData(slug)
    },[])


    return (
        <>
            {!data && !loading &&<div className="containerNoData"> 
                <h3>Cardapio não encotrado!</h3> 
                <p>Escanei o QRcode na mesa!</p>
                <img src={QRCode} alt="Qr code" className="qrcodeHome"/>
                </div>
            }
             { loading && <div className="loading" ><CircularProgress size={80} /></div>}
           { !loading &&
           <List>
                {
                  data && data.categories.map(category=>{
                        return(
                             <CollapsibleList
                                key={category.id}
                                handle={
                                  <SimpleListItem
                                    text={category.name}                  
                                    metaIcon="chevron_right"
                                  />
                                }
                                onOpen={() => console.log('open')}
                                onClose={() => console.log('close')}
                              >
                              <div className="containerProductCatList" >
                                  <ul>
                                      {
                                          category.products.map(product=>{
                                              return(
                                                <li onClick={()=>handleClick(product.id, category.id)} key={product.id}>                         
                                                    <div className="produtctDetails">
                                                        <h2 className="productTitle">{product.name}</h2>
                                                        <p className="productDescription">{product.description}</p>
                                                        <p className="productPrice">R$ {product.value.toFixed(2)}</p>                                
                                                    </div>
                                                    <div className="productImage">
                                                        <img src={product.images.length>0?`${API_URL}download/img/${product.images[0].path}`:"images/image_not_found.png"} alt=""/>
                                                    </div>                                            
                                                </li>
                                              )
                                          })
                                      }                                     
                                    </ul>                
                             </div>
                              </CollapsibleList>
                        )
                    })
                }
                                          
          </List> }

          <Dialog
                    open={open}
                    onClose={evt => {
                    console.log(evt.detail.action);
                    setOrderQuantity(1)
                    setOpen(false);
                    }}
                    onClosed={evt => setAttributes(null)}
                >        
                            <div className="productImageModal">
                            <img src={product && product.images.length>0?`${API_URL}download/img/${product.images[0].path}`:"images/image_not_found.png"} alt=""/>
                            </div>
                            <div className="produtctDetailsModal">
                                <h2 className="productTitle">{product?product.name:""}</h2>
                                <p className="productDescription">{product?product.description:""}</p> 
                                {   attributes &&
                                    attributes.length>0 && attributes.map(
                                        att=>{
                                            return(
                                                <div key={att.id} className="attributeContainer">
                                                    <div className="nameCountAtt">
                                                    <div className="nameAttribute">{att.title} </div><div className="countAttribute">{att.count}/{att.max_item}</div><div className="requiredAttribute">{att.required?"OBRIGATÓRIO":"OPCIONAL"}</div>
                                                    </div>
                                                    <div className="descriptionAttribute">{att.description}</div>
                                                    {att.values.map(value=>{
                                                        return(
                                                            <div key={value.id} className="attValue">
                                                                <div className="nameValue">{value.name}</div> 
                                                                <div className="addValue">
                                                                <Checkbox label="" onChange={evt=>addComplement(value, evt.currentTarget.checked) } disabled={att.count === att.max_item && value.checked === false ?true:false}/>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        }
                                    )
                                }       
                                <TextField label="Deixe uma observação" type="text" {...observation} />                     
                                <p className="productPrice">R$ {product?(product.value*orderQuantity).toFixed(2):0}</p>
                                <div className="modalItemControl">
                                    <div className="qtyControl">
                                        <IconButton icon="remove" className="plusMinus" onClick={()=>{setOrderQuantity(orderQuantity>1?orderQuantity-1:orderQuantity-0)}}/>
                                            <div className="qtyInput">{orderQuantity}</div>
                                        <IconButton icon="add" className="plusMinus"  onClick={()=>{setOrderQuantity(orderQuantity+1)}}/>
                                    </div>
                                    {product && <Button className="produtctDetailsBtnAdd" label="Adicionar" onClick={()=>{addItemToChart({product_id:product.id, name:product.name, observation:observation.value, value:product.value}, orderQuantity)}}/>}
                                </div>
                            </div>
                </Dialog>

                <Snackbar
                    open={openSnack}
                    onClose={evt => setOpenSnack(false)}
                    message={messageSnack}
                    dismissesOnAction
                    action={
                    <SnackbarAction
                        label="Ok"
                        onClick={() => setMessageSnack('')}
                    />
        }
      />
      <ButtonCall/>
        </>
    )
}

export default ListaCardapioHome

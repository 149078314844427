import React from 'react'
import { Fab, Snackbar, SnackbarAction } from 'rmwc'
import {CALL_WAITER} from '../../Api'
import {redirect} from '../../Utils'
import './Button.css'
function ButtonCall(){
    const [openSnack, setOpenSnack] = React.useState(false)
    const [messageSnack, setMessageSnack] = React.useState('')
    const token = window.localStorage.getItem('tmenu-ct')


    async function callWaiter(){    
        const slug = window.localStorage.getItem('slug')
        if(!token){
          redirect('login')
        }else{
          try {
            if(slug && slug!=='0'){
              const {url, options} = CALL_WAITER(slug, token)
              const response = await fetch(url, options)
              if (!response.ok) throw new Error(response.statusText)
              const {message} = await response.json()
              if(message){
                setMessageSnack("O garçom foi solicitado!")
                setOpenSnack(true)
              }else{
                setMessageSnack("Desculpe, tivemos um problema com a solicitação!")
                setOpenSnack(true)
              }
              
            }else{
              setMessageSnack("Por favor escanei o QR Code em uma mesa!")
              setOpenSnack(true)
            }
          } catch (error) {
            console.log(error)            
            setMessageSnack("Desculpe, houve um problema com a solicitação!")
            setOpenSnack(true)
          }
        }
       
      }
    
    return(
    <div className="btnCallContainer">
        <Fab label="Chamar garçom" className={"getWaiter"} onClick={callWaiter} icon={{icon:'/images/room_service.svg', size:'small'}}/>
        <Snackbar
            open={openSnack}
            onClose={evt => setOpenSnack(false)}
            message={messageSnack}
            dismissesOnAction
            action={
            <SnackbarAction
                label="Ok"
                onClick={() => setMessageSnack('')}
            />
            }
        />
  </div>
    )
}

export default ButtonCall
import {config} from './ApiUrls'
export const API_URL = config.url.API_URL
//AUTH

export function TOKEN_POST(body){
    return{
        url:API_URL+"auth/login",
        options:{
            method:'POST',
            headers:{
              'Content-Type': 'application/json'
             },
             body:JSON.stringify(body)
        }
    }
}
//MENU

export function MENU(hashCode){
    return{
        url: `${API_URL}${hashCode}`,
        options:{
            method:'GET',
            headers:{
              'Content-Type': 'application/json'
             }
          }
    }
}

export function CALL_WAITER(slug, token){
    return{
        url:`${API_URL}client/call-waiter/${slug}`,
        options:{
            method:'POST',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
             }
          }
    }
}

export function SEARCH(search, page, limit, companyId){
    const url = `${API_URL}client/product?page=${page}&limit=${limit}&company_id=${companyId}&search=${search}`
    return{
        url: url,
        options:{
            method:'GET',
            headers:{
              'Content-Type': 'application/json'
             }
          }
    }
}

export function GET_PRODUCT(id){
    return{
        url:`${API_URL}client/product/${id}`,
        options:{
            method:'GET',
            headers:{
              'Content-Type': 'application/json'
             }
          }
    }
}


//ORDERS

export function GET_ORDERS(token){
    return{
        url:API_URL+"client/card",
        options:{
            method:'GET',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
             }
          }
    }
}

export function GET_ORDER(token, id){
    return{
        url:`${API_URL}client/card/${id}`,
        options:{
            method:'GET',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
             }
          }
    }
}

export function PUT_CLOSE_ACCOUNT(token, scope, id ){
    return{
        url:`${API_URL}client/${scope}/${id}`,
        options:{
            method:'PUT',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
             }
          }
    }
}


export function POST_ORDER(token, body){
     let seen = []
    return{
        url:`${API_URL}client/order`,
        options:{
            method:'POST',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
             },
             body:JSON.stringify(body, function(key, val){
                if (typeof val == "object") {
                  if (seen.indexOf(val) >= 0) {
                      return
                  }
                  seen.push(val);
              }
              return val;
            })
          }
    }
}

//TABLE

export function GET_TABLE(token, id){
    return{
        url:`${API_URL}client/table/${id}`,
        options:{
            method:'GET',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
             }
          }
    }
}

export function GET_TABLE_SLUG(token, slug) {
    return{
        url:`${API_URL}client/table?slug=${slug}`,
        options:{
            method:'GET',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
             }
          }
    }
}


//USER

export function GET_USER(token){
    return{
        url:API_URL+"client/user",
        options:{
            method:'GET',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
             }
          }
    }
}

export function POST_GUEST_USER(){
    return{
        url:API_URL+"client/user",
        options:{
            method:'POST',
            headers:{
              'Content-Type': 'application/json',
             }
          }
    }
}

export function POST_USER(body){
    return{
        url:API_URL+"client/user",
        options:{
            method:'POST',
            headers:{
              'Content-Type': 'application/json',
             },
             body: JSON.stringify(body)
          }
    }
}

export function PUT_USER(token, body, id){
    return{
        url:`${API_URL}client/user/${id}`,
        options:{
            method:'PUT',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
             },
             body: JSON.stringify(body)
          }
    }
}


//SOCIAL LOGIN
export function REDIRECT(provider){
    return{
        url:`${API_URL}login/${provider}`,
        options:{
            method:'GET',
            headers:{
              'Content-Type': 'application/json'
             }
          }
    }
}
import React from 'react';
import { TabBar, Tab, Badge, Button, Drawer, 
  DrawerHeader, Icon, DrawerTitle, DrawerContent, 
  List, ListItem, Dialog, Snackbar, SnackbarAction} from 'rmwc';
import CartIndex from './Cart/CartIndex';
import {UserContext} from '../UserContext'
import AccountIndex from './Account/AccountIndex';
import { GET_ORDERS } from '../Api'

function MainMenu() {
  const { userLogout, user, chartCount } = React.useContext(UserContext)
  const [open, setOpen] = React.useState(false)
  const [openSnack, setOpenSnack] = React.useState(false)
  const [openCart, setOpenCart] = React.useState(false)
  const [openAccount, setOpenAccount] = React.useState(false)
  const [stageOne, setStageOne] = React.useState(true)
  const [stageTwo, setStageTwo] = React.useState(false)
  const [stageAccount, setStageAccount] = React.useState(false)
  const [card, setCard] = React.useState(null)
  const [contentQuest, setContentQuest] = React.useState(true)
  const [contentConfirmed, setContentConfirmed] = React.useState(false)
  const [messageAccount, setMessageAccount] = React.useState('')
  const [messageSnack, setMessageSnack] = React.useState('')
  const [type, setType] = React.useState(0)
  const token = window.localStorage.getItem('tmenu-ct')
  const openChart = window.localStorage.getItem('open-chart')
    function redirect(path){
      setOpen(false)
      window.location.href = `/${path}`
    }
    function logout(){
      userLogout()
      setOpen(false)
    }

    function getAccount(type){
      getOrder()
      if(type === 1){
        setMessageAccount("Tem certeza que deseja pedir apenas a sua conta?")
        setType(1)
      }else if(type === 2){
        setMessageAccount("Tem certeza que deseja pedir a conta da mesa?")
        setType(2)
      }
      setOpenAccount(true)
      
    }

    function setHistoryValue(){
      setOpenCart(openChart?openChart:false)
    }

    async function getOrder(){
      try {
          if(token){ 
              const {url, options} = GET_ORDERS(token)
              const response = await fetch(url, options)
              if(!response.ok) throw new Error(response.statusText)
              const {cards} = await response.json()
              const idx = cards.data.findIndex((card)=>card.status === true)
              const card = cards.data[idx]
              setCard(card)
          }
          return null  
      } catch (error) {
          console.log(error)
          return null
      }
  } 

    React.useState(()=>{
      setHistoryValue()
    },[])
    React.useEffect(()=>{
    },[chartCount])

    return (
      <>
    
     
       <Dialog
        open={openChart?openChart:openCart}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenCart(false);
          setStageOne(true);
          setStageTwo(false);
          setStageAccount(false);
          setContentConfirmed(false)
        }}
        
        onClosed={evt => console.log(evt.detail.action)}
      >

          <CartIndex setOpen={setOpenCart} open={openCart} 
          setStageOne={setStageOne} stageOne={stageOne} 
          setStageTwo={setStageTwo} stageTwo={stageTwo}
          setStageAccount={setMessageAccount} stageAccount={stageAccount}
          contentConfirmed={contentConfirmed} setContentConfirmed={setContentConfirmed}
          />

      </Dialog>
      

      <Dialog
        open={openAccount}
        onClose={evt => {
          console.log(evt.detail.action);
          setOpenAccount(false);
          setContentQuest(true)
          setContentConfirmed(false)
        }}
        
        onClosed={evt => console.log(evt.detail.action)}
      >

          <AccountIndex message={messageAccount} type={type} open={openAccount} setOpen={setOpenAccount} 
           contentQuest={contentQuest} setContentQuest={setContentQuest}
           contentConfirmed={contentConfirmed} setContentConfirmed={setContentConfirmed}
           card={card}
          />

      </Dialog>
      <div classNmae="mainContainer">
        <div lassName={"container"}>
        
        </div>
       
        
            <TabBar className={"MainMenu"}>
              
              <Tab  restrictIndicator stacked icon="home" label="Início" onClick={()=>redirect('')}/>
              <Tab restrictIndicator stacked icon="account_circle" label="Minha Conta" onClick={() => setOpen(!open)}/>          
              <Tab restrictIndicator stacked icon="shopping_cart" label="Pedidos" onClick={()=>setOpenCart(true)}><Badge align="end" label={chartCount?chartCount:"0"}/></Tab>            
            </TabBar>
            
            <Drawer modal open={open} onClose={() => setOpen(false)}>

            <DrawerHeader>
              <DrawerTitle>Minha Conta</DrawerTitle>          
            </DrawerHeader>
              <div className='avatarContainer'>
                <img src={user && user.social_avatar?user.social_avatar:"/images/no-avatar128.png"} alt=''/>
                <p>{user?user.name.slice(0,16):<ListItem onClick={()=>redirect('login')}><Icon icon="" />Login</ListItem>}</p>
              </div>
            <DrawerContent>
              <List>
                <ListItem   onClick={()=>redirect('meus-pedidos')} ><Icon icon="list" />  Meus Pedidos </ListItem>
                <ListItem onClick={()=>redirect('profile')}><Icon icon="person" /> Meu Cadastro</ListItem>

                { user && <ListItem onClick={logout}><Icon icon="logout" />Sair</ListItem>}
                { /*!user &&<ListItem onClick={()=>redirect('login')}><Icon icon="" />Login</ListItem>*/}
              </List>              
            </DrawerContent>
              <div className="btnToBottom">
                  {user && <Button className="tmenuGreenBg" label="Pedir conta individual" icon="account_balance_wallet" unelevated onClick={()=>{getAccount(1)}} disabled={user?false:true}/>}
                  {user && <Button className="tmenuRedBg" label="Pedir conta da mesa" icon="groups" unelevated onClick={()=>{getAccount(2)}} disabled={user?false:true}/>}
              </div>
          </Drawer>
          </div>
          <Snackbar
            open={openSnack}
            onClose={evt => setOpenSnack(false)}
            message={messageSnack}
            dismissesOnAction
            action={
              <SnackbarAction
                label="Ok"
                onClick={() => setMessageSnack('')}
              />
        }
      />
      </>
    );
  }
  
  export default MainMenu;

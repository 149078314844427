import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { API_URL, MENU, TOKEN_POST, GET_USER, REDIRECT, POST_GUEST_USER } from './Api';

export const UserContext = React.createContext();

export const UserStorage =({children})=>{
    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [loged, setLoged] = React.useState(false)
    const [login, setLogin] = React.useState(null)
    const [company, setCompany] = React.useState(null)
    const [user, setUser] = React.useState(null)
    const [error, setEror] = React.useState(null)
    const [chartCount, setChartCount] = React.useState(0)
    const [updatedChart, setUpdatedChart] = React.useState(null)
    const navigate = useNavigate()
    const location = useLocation()
    const slugLogin = window.localStorage.getItem('slug')
    const {slug} = useParams()
    const [statusChart, setStatusChart] = React.useState(false)




    async function guestLogin(){
        const history = window.localStorage.getItem('history')
        try {
            const {url, options} = POST_GUEST_USER()
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {token} = await response.json()
            if(getUser(token)){
                setLoged(true)  
                window.localStorage.setItem('tmenu-ct',token)                             
            }else{
                navigate(login)
            }
            if(history){
                window.localStorage.setItem('open-chart', true)
                //window.location.reload()
                navigate(history)
            }else if(slugLogin){
                navigate(`/mesa/${slugLogin}`)    
            }else{
                //window.history.back()
                navigate(`/mesa/0`)    
            }
        } catch (error) {
            console.log("CLS",error)
        }
    }
    async function socialLogin(token){
        const history = window.localStorage.getItem('history')
        try {
            setEror(null)
          if(getUser(token)){
              setLoged(true)
              window.localStorage.setItem('tmenu-ct',token)
              if(history){
                window.localStorage.setItem('open-chart', true)
              }
              return true
          } 
        } catch (error) {
            console.log(error)
            window.localStorage.removeItem('tmenu-ct')
            setEror(error.message)
            setData(null)
            setLoged(false)
            setLoading(false)
            setUser(null)
            setLoged(false) 
            return false
        }
      }
    async function userLogin(username, password){
        try {
            setEror(null)
            setLoading(true)
            const {url, options} = TOKEN_POST({email:username, password})
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(response.statusText)
            const {data} = await response.json()
            if(getUser(data.token)){
                setLoged(true)
                window.localStorage.setItem('tmenu-ct',data.token)
                if(slugLogin){
                    navigate(`/mesa/${slugLogin}`)    
                }else{
                    //window.history.back()
                    navigate(`/mesa/0`)    
                }
            }
        } catch (error) {
            console.log(error)
            window.localStorage.removeItem('tmenu-ct')
            setEror(error.message)
            setData(null)
            setLoged(false)
            setLoading(false)
            setUser(null)
            
            setLoged(false)           
        }finally{
            setLoading(false)
        }
    }

    function getLoged(){
        return loged === true
    }
    async function userLogout(){
        setData(null)
        setLoged(false)
        setLoading(false)
        setUser(null)
        window.localStorage.removeItem('tmenu-ct')
        window.localStorage.removeItem('slug')
        navigate('/login')
    }


    function setUpdateUser(token){
        getUser(token)
    }


    async function getUser(token){
    try {
        if(!token) return false
        const {url, options} = GET_USER(token)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        const {user} = await response.json()
        if(user){
            user.roles.map(role=>{
                if(role.slug ==='client'){
                    setUser(user)
                    return true
                }else{
                    return false
                }
            })
        }else{
            return false
        }
    } catch (error) {
      console.log(error)
      window.localStorage.removeItem('tmenu-ct')
      setEror(error.message)
      setData(null)
      setLoged(false)
      setLoading(false)
      setUser(null)
      setLoged(false) 
      navigate('/login')
    }
    }

    async function getData(urlSlug){
        try {
            const {url, options} = MENU(urlSlug)
            const response = await fetch(url, options)
            if(response.status === 404) return null
            if(!response.ok) throw new Error(response.statusText)
            const {cardapio} = await response.json()          
            setData(cardapio)
            setCompany({
                id:cardapio.id,
                name:cardapio.name,
                image:cardapio.image,
                table:cardapio.table,
            })
        } catch (error) {
            console.log(error)
            setData(null)
            setCompany(null)
        }finally{
            setLoading(false)
        }
    }

    function getChart(){
        const chart = window.localStorage.getItem('tm-chart')
        if(chart){
          const arr = JSON.parse(chart)
          setChartCount(arr.length)        
        }else{
          setChartCount(0)
        }  
      }

    function updateChart(){
        const updatedIn = new Date()
        setUpdatedChart(updatedIn)
    }  


React.useEffect(()=>{
    //getData()
},[])

React.useEffect(()=>{
    async function autoLogin(){
        const token = window.localStorage.getItem('tmenu-ct')
        if(getUser(token)){
            //window.localStorage.setItem('tmenu-ct',data.token)
            setLoged(true)
        }
    }
    getChart()
    autoLogin()
},[])
    return(
        <UserContext.Provider 
            value={{data, loading, company, slug, loged, user, updatedChart,error, statusChart, setStatusChart,
                 getData, userLogin, userLogout, getLoged, chartCount, getChart, updateChart, setUpdateUser, socialLogin, 
                 guestLogin}}
        >
            {children}
        </UserContext.Provider>
    )
}
import React from 'react'
import { useParams, useNavigate } from "react-router-dom";
import {UserContext} from '../../UserContext'
function Authenticator(){
    const {token} =  useParams()
    const navigate = useNavigate()
    const { socialLogin } = React.useContext(UserContext)
    const localToken = window.localStorage.getItem('tmenu-ct')
    const history = window.localStorage.getItem('history')

    function althenticated(){
        console.log(token)
        if(localToken === token){
            navigate('/')
        }else{           
            if(socialLogin(token)){
                if(history)
                navigate(history)
                else
                navigate('/')
            }else{
                navigate('/login')
            }
        }  
    }
    React.useEffect(()=>{
        althenticated()
    },[])
    return(<></>)

}

export default Authenticator
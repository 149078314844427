const prod = {
    url: {
     API_URL: `https://api.tmenu.com.br/v1/`,
     URL_WS:`wss://api.tmenu.com.br`
   }
   };
   const dev = {
    url: {
     API_URL: `http://localhost:3333/v1/`,
     URL_WS: `ws://localhost:3333`
    }
   };
   export const config = process.env.NODE_ENV === `development` ? dev : prod;
export function convertDate(newDate, connector=''){
    const day = newDate.slice(8,10)
    const month = newDate.slice(5,7)
    const year =newDate.slice(0,4)
    const hour = newDate.slice(11,13)
    const minute = newDate.slice(14,16)
    return `${day}/${month}/${year} ${connector} ${hour}:${minute}`
}

export   function redirect(path){
    window.location.href = `/${path}`
  }